import { createRouter, createWebHistory } from 'vue-router'

const loginRoute = {
  path: '/login',
  name: 'Login',
  component: () => import('@/layouts/authLayout.vue'),
  children: [ 
    {
      path: '/login',
      name: 'login-screen',
      component: () => import('../views/auth/Auth.vue'),
      meta: {
        title: 'Авторизация',
      }
    },
    {
      path: '/login/confirm-email',
      name: 'login-confirm-email',
      component: () => import('../views/change-pass/ConfirmEmail.vue'),
      meta: {
        title: 'Подтверждение электронной почты',
      }
    },
    {
      path: '/login/new-pass',
      name: 'login-change-password',
      component: () => import('../views/change-pass/ChangePassword.vue'),
      meta: {
        title: 'Смена пароля',
      }
    }
  ]
};

const mainRoute = {
  path: '/',
  name: 'Main',
  component: () => import('../layouts/mainLayout'),
  children: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue'),
      meta: {
        title: 'Главная'
      }
    },
      
    {
      path: '/about',
      name: 'About',
      component: () => import('../views/About.vue')
    },
      
    {
      path: '/info',
      name: 'Info',
      alias: ["/info?tab=contact-info", "/info?tab=address"],
      component: () => import('../views/org-info/OrgInfo.vue'),
      meta: {
        title: 'Контактные данные организации',
        breadcrumbs: [
          {
            text: 'Контактные данные организации',
            active: true,
            to: {
              name: 'Info'
            }
          }
        ]
      }
    },
      
    {
      path: '/appeals',
      name: 'Appeals',
      alias: ["/appeals?tab=appeals", "/appeals?tab=archive"],
      component: () => import('../views/appeals/Appeals'),
      meta: {
        title: 'Работа с обращениями',
        breadcrumbs: [
          {
            text: 'Обращения',
            active: true,
            to: {
              name: 'Appeals'
            }
          },
          {
            text: 'Категории и темы',
            active: false,
            to: {
              name: 'Categories'
            }
          }
        ]
      }
    },
      
    {
      path: '/categories',
      name: 'Categories',
      component: () => import('../views/categories/Categories'),
      meta: {
        title: 'Темы и категории',
        breadcrumbs: [
          {
            text: 'Обращения',
            active: false,
            to: {
              name: 'Appeals'
            }
          },
          {
            text: 'Категории и темы',
            active: true,
            to: {
              name: 'Categories'
            }
          }
        ]
      }
    },
      
    {
      path: '/appeals/:id',
      name: 'Appeal',
      component: () => import('../views/appeals/Appeal'),
      props: true,
      meta: {
        title: 'Обращение',
        breadcrumbs: [
          {
            text: 'Обращения',
            active: true,
            to: {
              name: 'Appeals'
            }
          },
          {
            text: 'Категории и темы',
            active: false,
            to: {
              name: 'Categories'
            }
          }
        ]
      }
    },
      
    {
      path: '/page-not-found',
      component: () => import('../views/NotFound.vue'),
      meta: {
        title: "Страница не найдена"
      }
    },
      
    {
      path: '/:catchAll(.*)',
      redirect:'page-not-found'
    }
  ]
};

const routes = [
  loginRoute,
  mainRoute
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const publicPages = ['/login','/about', '/login/confirm-email', '/login/new-pass'];

router.beforeEach((to, _, next) => {
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  document.title = to.meta.title;
  if (authRequired && !loggedIn) {
      next('/login');
  } else {
      next();
  }
});

export default router
