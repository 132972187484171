import { createApp } from 'vue';
import App from './App.vue';
import Maska from 'maska';
import router from './router';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import './assets/css/style.scss';
import './assets/css/colors.scss';


const app = createApp(App);

app.use(router);
app.use(Maska);

app.mount('#app');